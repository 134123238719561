<template>
  <div id="mainColumn1">
    <module-404></module-404>
  </div>
</template>

<script lang="ts">
import Module404 from '@/components/modules/Module404.vue';
export default {
  components: {
    Module404
  }
};
</script>
